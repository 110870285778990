import './App.css';

function App() {

  const emailData = {
    name: 'kamil',
    domain: 'cieplicki',
    tld: 'com'
  }

  const handleEmail = () => {
    const { name, domain, tld } = emailData;
    window.location.href = `mailto: ${name}@${domain}.${tld}`;
    return;
  }

  return (
    <div className="App">
      <h1>Work in progress...</h1>
      <span>
        <a href='#' data-name='kamil' data-domain="cieplicki" data-tld="com" onClick={handleEmail}></a>
      </span>
    </div>
  );
}

export default App;
